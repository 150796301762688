<template>
    <div class="column" :class="[$mq]" :style="{ width: computedWidth }">
        <header>
            <span :class="['quantity', data.color]">{{ sortedTimers.length }}</span>
            <span class="text">{{ data.name }}</span>
        </header>
        <div class="content">
            <Timer @clickTimer="clickTimer(timer.id)" v-for="timer in sortedTimers" :visibilityType="visibilityType" :key="timer.id" :data="timer"></Timer>
        </div>
    </div>
</template>

<script>
import Timer from '@/components/domain/timerproduct/timer.vue'
import { TIMERPRODUCT_STATUS } from '@/constants'

export default {
    components: { Timer },
    props: {
        data: { type: Object },
        timers: { type: Array },
        visibilityType: { type: Number },
        countColumns: { type: Number }
    },
    data() {
        return {}
    },
    computed: {
        computedWidth() {
            return this.countColumns > 3 ? '30%' : '33%'
        },
        sortedTimers() {
            return [...this.timers].sort((a, b) => {
                const aTime = this.getComparisonTime(a)
                const bTime = this.getComparisonTime(b)
                if (this.visibilityType == 2) {
                    return this.getTotalSeconds(bTime) - this.getTotalSeconds(aTime)
                } else {
                    return this.getTotalSeconds(aTime) - this.getTotalSeconds(bTime)
                }
            })
        }
    },
    methods: {
        clickTimer(id) {
            console.log('clickTimer', id)
            this.$emit('clickTimer', id)
        },
        getComparisonTime(timer) {
            var now = moment()
            var diffReturn = null
            if (this.visibilityType == 1) {
                var ref_date = timer.data.date_timestamp
                if (timer.status == TIMERPRODUCT_STATUS.PREPARATION) {
                    ref_date = timer.data.prep_date_timestamp
                }
                var duration = moment.duration(moment(ref_date * 1000).diff(now))
                var diff = duration.asSeconds()
                let diff_days = parseInt(diff / 86400)
                diff = diff - diff_days * 86400
                let diff_hours = String(parseInt(diff / 3600)).padStart(2, '0')
                diff = diff - diff_hours * 3600
                let diff_minutes = String(parseInt(diff / 60)).padStart(2, '0')
                diff = diff - diff_minutes * 60
                let diff_seconds = String(parseInt(diff)).padStart(2, '0')

                var diffReturn = {
                    d: diff_days,
                    h: diff_hours,
                    m: diff_minutes,
                    s: diff_seconds
                }
            } else {
                var ref_date = timer.data.print_date_timestamp
                var duration = moment.duration(now.diff(moment(ref_date * 1000)))
                var diff = Math.abs(duration.asSeconds())
                let diff_days = parseInt(diff / 86400)
                diff = diff - diff_days * 86400
                let diff_hours = String(parseInt(diff / 3600)).padStart(2, '0')
                diff = diff - diff_hours * 3600
                let diff_minutes = String(parseInt(diff / 60)).padStart(2, '0')
                diff = diff - diff_minutes * 60
                let diff_seconds = String(parseInt(diff)).padStart(2, '0')

                var diffReturn = {
                    d: diff_days,
                    h: diff_hours,
                    m: diff_minutes,
                    s: diff_seconds
                }
            }

            return diffReturn
        },
        getTotalSeconds(time) {
            return time.d * 86400 + time.h * 3600 + time.m * 60 + time.s
        }
    },
    created() {}
}
</script>

<style lang="scss" scoped>
.column {
    flex: 0 0 auto;
    width: 33%;
    display: inline-block;
    vertical-align: top;
    height: 100%;
    overflow: hidden;

    &:last-child {
        margin-right: 0;
    }

    header {
        @include display-flex();
        @include justify-content(center);
        @include align-items(center);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        text-align: left;
        width: 100%;
        text-align: center;
        height: 40px;
        cursor: pointer;
        padding: 5px;
        text-transform: uppercase;
        background-color: #ddd;

        .text {
            @include font-size(sm);
            @include text-ellipsis();
            font-family: $text;
            color: $neutro-s90;
            text-align: left;
            padding-left: 8px;
            width: 100%;
        }

        .quantity {
            background-color: $inactive;
            padding: 6px 8px;
            width: auto;
            text-align: center;
            font-size: 18px;
            border-radius: 4px;
            font-family: Avenir-Bold;
            background-color: $color-error-500;
            color: #fff;

            &.ok {
                background-color: $done;
            }
            &.error {
                background-color: $color-error-500;
            }
        }
    }

    .content {
        background-color: #fff;
        width: 100%;
        height: calc(100% - 40px);
        display: grid;
        grid-template-columns: 50% 50%;
        grid-auto-rows: min-content;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 4px;
        gap: 2px;
    }
    .content ::v-deep .timer {
        width: 98%;
        height: 120px;
        margin: 2px;
        padding: 2px;
        box-sizing: border-box;
    }
}
</style>
